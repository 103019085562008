<template>
  <div>
    <h5 style="color: red">{{txtNombre}}</h5>    
    <p>{{msg}}</p>
    
    <input type="text" @change="consultar()" v-model="txtId"/>
    <button @click="consultar()">Consultar</button>
    <p><input type="text" v-model="txtValorNombre"/></p>
  </div>
</template>

<script>

import axios from 'axios';
export default {
  name: 'User', 
  created() {
    // Se llama automáticamente por Vue.js después de que se haya creado una instancia del componente,     pero antes de que se haya montado en el DOM.
    this.inicializarDatos();
  },
    
  data() {
    return {
      txtId:''
    };
  },
  props: {
    id:String,
    msg: String
  },
  methods: {
    inicializarDatos() {// Realizar inicialización de datos aquí
      this.txtId = this.id;
    },    
    consultar(){
      //alert(this.txtNombre);

      const apiUrl = 'https://api.extremanet.net/items/read/'+this.txtId;    
      const headers = {apikey: '12345678'};
      // Realiza la solicitud HTTP GET a la API
      axios
        .get(
          apiUrl, 
          { headers }
        )
        .then(response => {
            // Extrae el valor del campo "name" del JSON de respuesta
            if (response.data.data[0] && response.data.data[0].name !== null) {
              this.txtValorNombre = response.data.data[0].name;
            }else{
              this.txtValorNombre =  response.data.message;
            }
        })
        .catch(error => {
            //console.error('Error al obtener datos de la API:', error);
            if (error.response && error.response.status === 404) {
              // La solicitud devolvió un código de estado 404
              const errorMessage = error.response.data.message;
              //console.error('Mensaje de error 404:', errorMessage);
              this.txtValorNombre =  errorMessage;
            } else {
              // Otros errores
              console.error('Error desconocido:', error);
            }

        });   



    }
  },
};
</script>
 <!--   
   El método created() es uno de los "hooks" de ciclo de vida de un componente en Vue.js.
   
    Este hook es útil para realizar tareas de inicialización y configuración que deben ocurrir cuando 
    se crea el componente, pero antes de que se realice el proceso de montaje y renderización.
  -->

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
